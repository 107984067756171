<template>
  <div id="form" :class="{'success' : isValid === true,'failed' : isValid === false}">
    <input id="1" type="text" maxLength="1" size="1" min="0" max="9"  @input="onChange" v-model="code1" autofocus/>
    <input id="2" type="text" maxLength="1" size="1" min="0" max="9"  @input="onChange" v-model="code2"/>
    <input id="3" type="text" maxLength="1" size="1" min="0" max="9"  @input="onChange" v-model="code3"/>
    <input id="4" type="text" maxLength="1" size="1" min="0" max="9"  @input="onChange" v-model="code4"/>
  </div>
</template>

<script>
export default {
  name: "CodeInput",
  props:{
    code: String,
    isValid: Boolean
  },
  watch:{
    code:{
      immediate:true,
      handler(newVal){
        const arr = newVal.split('')
        for (let i=0;i<4;i++){
          if (arr[i] == null) return;
          switch (i){
            case 0:
              this.code1 = arr[i]
              break;
            case 1:
              this.code2 = arr[i]
              break;
            case 2:
              this.code3 = arr[i]
              break;
            case 3:
              this.code4 = arr[i]
              break;
          }
        }
      }
    }
  },
  data(){
    return {
      code1:"",
      code2:"",
      code3: "",
      code4: ""
    }
  },
  computed:{
    code(){
      return `${this.code1}${this.code2}${this.code3}${this.code4}`
    }
  },
  methods:{
    goToNextInput(e) {
      const body = $('body');

      let key = e.which,
          t = $(e.target),
          sib = t.next('input');

      if (key != 9 && (key < 48 || key > 57)) {
        e.preventDefault();
        return false;
      }

      if (key === 9) {
        return true;
      }

      if (!sib || !sib.length) {
        sib = body.find('input').eq(0);
      }
      sib.select().focus();
    },

    goToPrevious(e){
      const body = $('body');

      let key = e.which,
          t = $(e.target),
          sib = t.prev('input');



      if (!sib || !sib.length) {
        sib = body.find('input').eq(0);
      }
      sib.select().focus();
    },
    onKeyDown(e) {
      const key = e.which;
      console.log(e)
      if (key === 9 || (key >= 48 && key <= 57)) {
        return true;
      }

      if (e.key === "Backspace" || e.key === "Delete") {
        this.goToPrevious(e)
        e.target.value = ''
        return e.preventDefault();// Don't do anything to the input value
      }


      e.preventDefault();
      return false;
    },

    onFocus(e) {
      $(e.target).select();
    },

    onChange(e){
      this.$emit('input',this.code)
    }

  },
  mounted() {
    const body = $('body');

    body.on('keyup', 'input', this.goToNextInput);
    body.on('keydown', 'input', this.onKeyDown);
    body.on('click', 'input', this.onFocus);
  }
}
</script>

<style scoped lang="scss">
#form {
  max-width: 240px;
  margin: 25px auto 0;

  input {
    margin: 0 10px;
    text-align: center;
    line-height: 35px;
    font-family: Roboto, 'sans-serif';
    font-weight: 400;
    font-size: 30px;
    outline: none;
    transition: all .2s ease-in-out;
    width: 40px;
    height: 57px;
    border-radius: 8px;
    background: #FFFFFF;
    border: none;
    box-shadow: inset 0 1px 3px 0 rgba(0,0,0,0.16);

    &:focus {
      border: 1px solid #6a1b9a;
    }

    &::selection {
      background: transparent;
    }
  }

  &.success{
    input{
      border: 1px solid green;
      &:focus{
        border: 1px solid green;
      }
    }
  }

  &.failed{
    input{
      border: 1px solid red;

      &:focus{
        border: 1px solid red;
      }
    }
  }
}
</style>

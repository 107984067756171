<template>
  <div
      v-bind:class="{
      'react-code-input-container': true,
      [className]: !!className
    }"
  >
    <p class="title" :class="{'show' : isValid === false}">Неверный код</p>
    <div class="react-code-input" :class="{'success' : isValid === true,'failed' : isValid === false}" id="form">
      <template v-for="(v, index) in values">
        <input
            :type="type === 'number' ? 'tel' : type"
            :pattern="type === 'number' ? '[0-9]' : null"
            :style="{
          }"
            :key="`${id}-${index}`"
            :data-id="index"
            :value="v"
            :ref="iRefs[index]"
            v-on:input="onValueChange"
            v-on:keydown="onKeyDown"
            v-on:focus="onFocus"
            :disabled="disabled"
            :required="required"
            maxlength="1"
        />
      </template>
    </div>
  </div>
</template>

<script>
const KEY_CODE = {
  backspace: 8,
  left: 37,
  up: 38,
  right: 39,
  down: 40
};
export default {
  name: "SmsCodeInput",
  props: {
    type: {
      type: String,
      default: "number"
    },
    code: String,
    className: String,
    fields: {
      type: Number,
      default: 4
    },
    autoFocus: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isValid: Boolean,
    title: String,
    change: Function,
    complete: Function,
  },
  data() {
    const { fields, values } = this;
    let vals;
    let autoFocusIndex = 0;
    if (values && values.length) {
      vals = [];
      for (let i = 0; i < fields; i++) {
        vals.push(values[i] || "");
      }
      autoFocusIndex = values.length >= fields ? 0 : values.length;
    } else {
      vals = Array(fields).fill("");
    }
    this.iRefs = [];
    for (let i = 0; i < fields; i++) {
      this.iRefs.push(`input_${i}`);
    }
    this.id = +new Date();
    return { values: vals, autoFocusIndex };
  },
  watch:{
    code:{
      immediate:true,
      deep:true,
      handler(newVal){
        if (this.$refs[`input_0`] == null) return;
        const arrChars = newVal.split("")
        for(let i=0;i<4;i++){
          this.values[i] =  (arrChars[i]  == null) ? "" : arrChars[i]
          this.$refs[`input_${i}`][0].value = (arrChars[i]  == null) ? "" : arrChars[i]
        }
        if (newVal.length === 0){
          this.onFocus(this.$refs[`input_0`][0].select())
        }
      }
    }
  },
  mounted() {},
  methods: {
    onFocus() {
      for(let i=0;i<4;i++){
        if (this.values[i] == ""){
          const el = this.$refs[this.iRefs[i]][0]
          el.select()
          break;
        }
      }

    },
    onValueChange(e) {
      const index = parseInt(e.target.dataset.id);
      const { type, fields } = this;
      if (type === "number") {
        e.target.value = e.target.value.replace(/[^\d]/gi, "");
      }
      // this.handleKeys[index] = false;
      if (
          e.target.value === "" ||
          (type === "number" && !e.target.validity.valid)
      ) {
        return;
      }
      let next;
      const value = e.target.value;
      let { values } = this;
      values = Object.assign([], values);
      if (value.length > 1) {
        let nextIndex = value.length + index - 1;
        if (nextIndex >= fields) {
          nextIndex = fields - 1;
        }
        next = this.iRefs[nextIndex];
        const split = value.split("");
        split.forEach((item, i) => {
          const cursor = index + i;
          if (cursor < fields) {
            values[cursor] = item;
          }
        });
        this.values = values;
      } else {
        next = this.iRefs[index + 1];
        values[index] = value;
        this.values = values;
      }
      if (next) {
        const element = this.$refs[next][0];
        element.focus();
        element.select();
      }
      this.triggerChange(values);
    },
    onKeyDown(e) {
      const index = parseInt(e.target.dataset.id);
      const prevIndex = index - 1;
      const nextIndex = index + 1;
      const prev = this.iRefs[prevIndex];
      const next = this.iRefs[nextIndex];
      switch (e.keyCode) {
        case KEY_CODE.backspace: {
          e.preventDefault();
          const vals = [...this.values];
          if (this.values[index]) {
            vals[index] = "";
            this.values = vals;
            this.triggerChange(vals);
          } else if (prev) {
            vals[prevIndex] = "";
            this.values = vals;
            this.$refs[prev][0].focus();
            this.triggerChange(vals);
          }
          break;
        }
        case KEY_CODE.left:
          e.preventDefault();
          if (prev) {
            this.$refs[prev][0].focus();
          }
          break;
        case KEY_CODE.right:
          e.preventDefault();
          if (next) {
            this.$refs[next][0].focus();
          }
          break;
        case KEY_CODE.up:
        case KEY_CODE.down:
          e.preventDefault();
          break;
        default:
          // this.handleKeys[index] = true;
          break;
      }
    },
    triggerChange(values = this.values) {
      const { fields } = this;
      const val = values.join("");
      this.$emit("input", val);
      if (val.length >= fields) {
        this.$emit("input", val);
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#form {
  max-width: 240px;
  margin: 0px auto 0;
  display: flex;

  input {
    margin: 0 10px;
    text-align: center;
    line-height: 35px;
    font-family: Roboto, 'sans-serif';
    font-weight: 400;
    font-size: 30px;
    outline: none;
    transition: all .2s ease-in-out;
    width: 40px;
    height: 57px;
    min-width: 40px;
    min-height: 57px;
    border-radius: 8px;
    background: #FFFFFF;
    border: none;
    box-shadow: inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    padding: 0;

    &:focus {
      border: 0;
    }

    &::selection {
      background: transparent;
    }
  }

  &.success {
    input {
      border: 1px solid green;

      &:focus {
        border: 1px solid green;
      }
    }
  }

  &.failed {
    input {
      border: 1px solid rgba(255,0,0,0.33);


      &:focus {
        border: 1px solid rgba(255,0,0,0.33);

      }
    }
  }
}


.title{
  font-family: Roboto, 'sans-serif';
  font-weight: 300;
  font-size: 13px;
  color: #FF5E5E;
  text-align: center;
  margin-bottom: 14px;
  margin-top: 20px;
  opacity: 0;

  &.show{
    opacity: 1;
  }
}

</style>
